<template>
  <div>
    <v-snackbar :color="color" v-model="snackbarModel">
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbarModel = false"> Fermer </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: "Snackbar",
  props: ["model", "message", "color"],
  data() {
    return {
      snackbarModel: false,
    };
  },
  watch: {
    model: function (newValue, oldValue) {
      this.snackbarModel = newValue;
    },
  },
};
</script>
