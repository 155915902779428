<template>
  <div class="about" style="height: 100%;width:100%">
    <div style="height: 100%;width:100%" class="d-flex flex-row justify-center align-center">
      <div style="width:25%;">
        <div class="pa-4 d-flex flex-column justify-space-around" style="border:2px solid #01579B;height:60vh">
          <div class="mb-6 d-flex flex-column">
            <img class="align-self-center mb-4" height="100" src="../../assets/logoSmart.png"></img>
            <!--<h2 class="text-center mt-4">Bon retour parmis nous !</h2>-->
          </div>
          <v-form ref="formLogin">
            <v-text-field
              outlined
              dense
              :rules="[$rules.required,$rules.emailRules]"
              width="100%"
              label="Email"
              v-model="user.email"
            >
              <template v-slot:append>
                <v-icon :color='$colors[0]'>mdi-at</v-icon>
              </template>
            </v-text-field>
            <v-text-field
              outlined
              dense
              :rules="[$rules.required]"
              label="mot de passe"
              v-model="user.password"
              :type="showPassword ? 'text' : 'password'"
            > 
              <template v-slot:append>
                  <v-icon v-if="showPassword" @click="showPassword = !showPassword"  :color='$colors[0]'>mdi-eye</v-icon>
                  <v-icon v-if="!showPassword" @click="showPassword = !showPassword" :color='$colors[0]'>mdi-eye-off</v-icon>
              </template>
            </v-text-field>
          </v-form>
          <v-btn  elevation="0" width="100%" :color="$colors[0]" class="white--text" @click="login()">Se connecter <v-icon dense class="ml-2">mdi-login-variant</v-icon></v-btn>
        </div>
        <Loading class="pa-0 align-self-center" :dialogProps="dialogLogin" :messageDialogeProps='messageErrorLogin' :loadingProps='loadingLogin' :errorProps='errorLogin' @closeDialog="dialogLogin = $event"/>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "@/components/Loading.vue"
export default {
  name: "login",
  components:{Loading},
  data() {
    return {
      user:{
        email: "test",
        password: "test",
      },

      showPassword: false,
      messageErrorLogin:"",
      dialogLogin:false,
      loadingLogin:false,
      errorLogin:false,
    }
  },
  methods: {
     async login() {
      this.loadingLogin = true;
      if(this.$refs.formLogin.validate()){
        await this.$axios
          .post(process.env.VUE_APP_URL_API_CLIENT + "login/", {
            email: this.user.email,
            password: this.user.password,
          })
          .then(async (res) => {
            if (res.data.login == true) {
              //set the vueAfficher to pro or particulier to know wich view we will open first
              //type of user for the header
              localStorage.setItem('email', this.user.email)
              localStorage.setItem('typeUser', res.data.type)
              localStorage.setItem('Telephone', res.data.Telephone)
              this.$setToken('token', res.data.token, 8*60*60)
              var whoIam = res.data.type
              this.$store.dispatch('changeAuthenticatedAction') // passe en true la variable isAuthenticated
              this.$router.push('/')
            }else{ // login == false
              this.errorLogin = true;
              this.dialogLogin = true;
              this.messageErrorLogin = res.data.message;
            }
          })
          .catch((err) => {
            console.log(err)
            
          })
          this.loadingLogin = false;
      }
      }
  }, 
};
</script>
