<template>
  <div>
    <!-- search -->
    <div
      v-if="searchVisible"
      class="d-flex justify-content-start align-items-center my-4"
    >
      <input
        class="form-control search-input"
        type="text"
        v-model="search"
        placeholder="Rechercher..."
        @input="$emit('search', { type: 'search', key: search })"
        style="width: 300px"
      />
    </div>

    <table
      class="table table-striped table-bordered"
      style="width: 100%; overflow-x: auto"
    >
      <!-- table head -->
      <thead class="">
        <tr>
          <th
            v-for="(column, index) in columns"
            style="text-align: center"
            :key="index"
            :class="
              sortKey == column.name
                ? sortOrders[column.name] > 0
                  ? 'sorting_asc'
                  : 'sorting_desc'
                : 'sorting'
            "
            class="tableHead Poppins-Bold"
            :style="
              'width:' +
              column.width +
              '; text-wrap: nowrap;height: 50px; cursor:pointer;'
            "
            @click="onColumnSortClicked(column.sortable, column.name)"
          >
            {{ column.label }}
          </th>
        </tr>
      </thead>

      <!-- table body -->
      <slot name="body"></slot>
    </table>

    <!-- pagination -->
    <Pagination
      v-if="paginationVisible"
      :pagination="pagination"
      @selectNumberOfRecords="$emit('rechercheData', $event)"
      @first="refreshDataEmit(pagination.firstPage)"
      @last="refreshDataEmit(pagination.lastPage)"
      @prev="refreshDataEmit(pagination.prevPageUrl)"
      @current="refreshDataEmit(pagination.currentPage)"
      @next="refreshDataEmit(pagination.nextPageUrl)"
    />
  </div>
</template>

<script>
import Pagination from "./Pagination.vue";

export default {
  name: "CustomDatatable",
  props: [
    "columns",
    "sortKey",
    "sortOrders",
    "isSearchable",
    "isPaginated",
    "refreshData",
    "paginationObjectProps",
  ],
  components: {
    Pagination,
  },
  data() {
    return {
      search: "",
      searchVisible: true,
      paginationVisible: false,
      pagination: {
        perPage: ["10", "15", "30"],
        firstPage: 1,
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
    };
  },
  watch: {
    paginationObjectProps: function (newValue, oldValue) {
      this.configPagination(newValue);
    },
  },
  mounted() {
    if (this.isSearchable == false) {
      this.searchVisible = false;
    }
    if (this.isPaginated) {
      this.paginationVisible = true;
    }
  },
  methods: {
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    refreshDataEmit(pagination) {
      this.$emit("refreshData", pagination);
    },

    onColumnSortClicked(sortable, key) {
      if (sortable) {
        this.$emit("sort", key);
      }
    },
  },
};
</script>

<style scoped>
@import "./css/styles.css";
</style>
