<template>
  <div class="d-flex flex-row justify-content-between pa-4" style="width: 100%">
    <div class="d-flex align-items-center">
      <span>éléments affichés</span>
      <select
        :style="{ borderColor: $colors[0] }"
        class="form-control selected"
        v-model="length"
        @change="$emit('selectNumberOfRecords', { type: 'nbrRecords', key: length })"
      >
        <option
          v-for="(records, index) in pagination.perPage"
          :key="index"
          :value="records"
        >
          <span>{{ records }}</span>
        </option>
      </select>
      <li class="page-stats">
        <span
          >{{ pagination.from.toLocaleString("fr-FR") }} -
          {{ pagination.total.toLocaleString("fr-FR") }}</span
        >
      </li>
    </div>
    <nav aria-label="Page navigation example">
      <ul class="pagination">
        <!-- got to first page button -->
        <li class="page-item">
          <a
            style="border: 1px white solid"
            :style="{ color: $colors[0] }"
            v-if="pagination.prevPageUrl && pagination.prevPageUrl >= 0"
            class="page-link"
            @click="$emit('first')"
          >
            &lt;&lt;</a
          >
          <a
            style="border: 1px white solid"
            :style="{ color: $colors[0] }"
            class="page-link"
            v-else
            :disabled="true"
          >
            &lt;&lt;</a
          >
        </li>

        <!-- got to prev page button -->
        <li class="page-item">
          <a
            style="border: 1px white solid"
            :style="{ color: $colors[0] }"
            v-if="pagination.prevPageUrl && pagination.prevPageUrl >= 0"
            class="page-link"
            @click="$emit('prev')"
          >
            &lt;</a
          >
          <a
            :style="{ color: $colors[0] }"
            style="border: 1px white solid"
            class="page-link"
            v-else
            :disabled="true"
          >
            &lt;</a
          >
        </li>

        <!-- got to prev page button with number -->
        <li
          class="page-item"
          v-if="
            pagination.prevPageUrl &&
            pagination.prevPageUrl >= 0 &&
            pagination.prevPageUrl != pagination.currentPage
          "
        >
          <a
            :style="{ color: $colors[0] }"
            style="border: 1px white solid"
            v-if="pagination.prevPageUrl >= 0"
            class="page-link"
            @click="$emit('prev')"
          >
            {{ pagination.prevPageUrl }}</a
          >
          <a
            :style="{ color: $colors[0] }"
            style="border: 1px white solid"
            class="page-link"
            v-else
            :disabled="true"
          >
            {{ pagination.prevPageUrl }}</a
          >
        </li>

        <!-- current page button with number -->
        <li class="page-item active">
          <span
            class="page-link rounded Poppins-Bold"
            :style="{ backgroundColor: $colors[0], borderColor: $colors[0] }"
          >
            {{ pagination.currentPage }}
          </span>
        </li>

        <!-- got to next page button with number -->
        <li
          class="page-item"
          v-if="
            pagination.nextPageUrl &&
            pagination.nextPageUrl <= pagination.lastPage &&
            pagination.nextPageUrl != pagination.currentPage
          "
        >
          <a
            :style="{ color: $colors[0] }"
            style="border: 1px white solid"
            v-if="pagination.nextPageUrl <= pagination.lastPage"
            class="page-link rounded"
            @click="$emit('next')"
          >
            {{ pagination.nextPageUrl }}
          </a>
          <a
            :style="{ color: $colors[0] }"
            style="border: 1px white solid"
            class="page-link"
            v-else
            :disabled="true"
          >
            {{ pagination.nextPageUrl }}
          </a>
        </li>

        <!-- go to next page button -->
        <li class="page-item">
          <a
            :style="{ color: $colors[0] }"
            style="border: 1px white solid"
            v-if="pagination.nextPageUrl && pagination.nextPageUrl <= pagination.lastPage"
            class="page-link"
            @click="$emit('next')"
          >
            &gt;
          </a>
          <a
            :style="{ color: $colors[0] }"
            style="border: 1px white solid"
            class="page-link"
            v-else
            :disabled="true"
          >
            &gt;
          </a>
        </li>

        <!-- go to last page button -->
        <li class="page-item">
          <a
            style="border: 1px white solid"
            :style="{ color: $colors[0] }"
            v-if="pagination.nextPageUrl && pagination.nextPageUrl <= pagination.lastPage"
            class="page-link"
            @click="$emit('last')"
          >
            &gt;&gt;
          </a>
          <a
            :style="{ color: $colors[0] }"
            style="border: 1px white solid"
            class="page-link"
            v-else
            :disabled="true"
          >
            &gt;&gt;
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    pagination: {
      type: Object,
      required: true,
      default: {
        perPage: ["10", "15", "30"],
        firstPage: 1,
        lastPage: 1,
        currentPage: 1,
        total: 0,
        lastPageUrl: 1,
        nextPageUrl: 1,
        prevPageUrl: 1,
        from: 1,
        to: 1,
      },
      validator: (propValue) => {
        let isTypeRight = false;
        for (const [key, value] of Object.entries(propValue)) {
          if (typeof value === "object" || typeof value === "number") {
            isTypeRight = true;
          }
        }
        return isTypeRight;
      },
    },
  },
  data() {
    return {
      perPage: ["15", "20", "30"],
      length: 15,
    };
  },
};
</script>

<style scoped>
.pagination {
  justify-content: center;
  margin: 0px;
}

.page-stats {
  list-style-type: none;
  margin-left: 24px;
}

.container {
  display: flex;
  align-items: center;
  margin-left: 16px;
  padding: 0;
}

.selected {
  width: 50px;
  margin-left: 5px;
  margin-right: 5px;
}

@media only screen and (max-width: 1280px) {
  .container {
    flex-direction: column;
    margin: 0px !important;
  }
}
@media only screen and (max-width: 1280px) {
  .page-stats {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 1280px) {
  .pagination {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>
